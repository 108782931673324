import { Input, Modal, Select, Spin } from 'antd';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { StudentInfo } from '../StudentDetail/StudentDetail';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getOne } from 'api/student.api';
import { notificationController } from 'controllers/notificationController';
import { useEffect, useState } from 'react';
import { doPatch, doClear } from 'store/slices/studentSlice';
import { SendCustomErrorMessage } from 'utils/Status&ErrorMessage';
import { Student, patternCurp } from '../types';
import { OptionsSelect } from 'types/optionsSelect';
import { doList as doListWorkCenters } from 'store/slices/workCenterSlice';

type StudentChangeWorkCenterModalProps = {
  openModal: boolean;
  OpenModalHanlder: (open: boolean) => void;
};

export const StudentChangeWorkCenterModal = ({
  openModal,
  OpenModalHanlder,
}: StudentChangeWorkCenterModalProps) => {
  const dispatch = useAppDispatch();

  const { workcenters, status: workcenterStatus } = useAppSelector(
    (state) => state.workcenter
  );

  const [curpInputValue, setCurpInputValue] = useState('');
  const [searchingStudentCurp, setSearchingStudentCurp] = useState(false);
  const [foundStudent, setFoundStudent] = useState<Student | undefined>();
  const [studentNewWorkCenter, setStudentNewWorkCenter] = useState('');
  const [updatingStudent, setUpdatingStudent] = useState(false);
  const [workcentersOptions, setWorkcentersOptions] =
    useState<OptionsSelect[]>();

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = workcenters.map((workCenter) => ({
      label: `${workCenter.nameShort} / ${workCenter.name}`!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setWorkcentersOptions(optionsRaw);
  }, [workcenters]);

  useEffect(() => {
    if (workcenterStatus !== 'fulfilled') {
      dispatch(doListWorkCenters());
    }
  }, [dispatch]);

  useEffect(() => {
    if (workcenterStatus !== 'fulfilled') {
      dispatch(doListWorkCenters());
    }
  }, [workcenters, workcenterStatus]);

  // PopUp
  const HideModal = () => {
    setSearchingStudentCurp(false);
    setFoundStudent(undefined);
    setStudentNewWorkCenter('');
    OpenModalHanlder(false);
  };

  const HandleCurpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurpInputValue(value);
  };

  const HandleStudentNewWorkCenterChange = (value: string) => {
    setStudentNewWorkCenter(value);
  };

  const SearchStudentByCurp = () => {
    setSearchingStudentCurp(true);
    if (!curpInputValue || !patternCurp.test(curpInputValue)) {
      setSearchingStudentCurp(false);
      notificationController.error({ message: 'Ingresa una CURP válida' });
      return;
    }
    getOne({ searchParam: 'curp', searchValue: curpInputValue })
      .then((data) => setFoundStudent(data))
      .catch((e) => {
        setFoundStudent(undefined);
        SendCustomErrorMessage({ error: e });
      })
      .finally(() => {
        setCurpInputValue('');
        setSearchingStudentCurp(false);
      });
  };

  const UpdateStudentWorkCenter = async () => {
    setUpdatingStudent(true);
    if (studentNewWorkCenter === '') {
      notificationController.error({
        message: 'Debes asignar un plantel para guardar cambios',
      });
      return;
    }

    const newWorkCenter = workcenters.find(
      (workcenter) => workcenter._id === studentNewWorkCenter
    );
    if (!newWorkCenter) {
      notificationController.error({
        message: 'No se ha encontrado el plantel, intenta recargar la página',
      });
      return;
    }

    await dispatch(
      doPatch({
        student: foundStudent!,
        changedValues: {
          workcenter: newWorkCenter,
          name: foundStudent?.name as string,
          email: foundStudent?.email as string,
          curp: foundStudent?.curp as string,
          academicStatus: foundStudent?.academicStatus as string,
        },
      })
    )
      .then((res) => {
        if (res.payload)
          notificationController.success({
            message: 'Se cambió el plantel correctamente',
          });
      })
      .catch((error) => SendCustomErrorMessage({ error }));
    dispatch(doClear());
    setSearchingStudentCurp(false);
    setUpdatingStudent(false);
    HideModal();
  };

  return (
    <Modal
      title={
        foundStudent === undefined
          ? 'Ingresa la curp del estudiante'
          : `Datos del estudiante`
      }
      open={openModal as boolean}
      cancelText={foundStudent === undefined ? 'Cerrar' : 'Descartar Cambios'}
      okText={foundStudent === undefined ? 'Buscar alumno' : 'Guardar Cambios'}
      okButtonProps={{ loading: updatingStudent || searchingStudentCurp }}
      onCancel={HideModal}
      onOk={
        foundStudent !== undefined
          ? UpdateStudentWorkCenter
          : SearchStudentByCurp
      }
    >
      {searchingStudentCurp ? (
        <Spin />
      ) : foundStudent === undefined ? (
        <Input value={curpInputValue} onChange={HandleCurpChange}></Input>
      ) : (
        <>
          <BaseForm layout='vertical'>
            <StudentInfo selectedRow={foundStudent} />
            <br />
            <BaseForm.Item
              name='subsystem'
              required
              label='Asigna un nuevo plantel al estudiante'
            >
              <Select
                showSearch
                value={studentNewWorkCenter}
                onChange={HandleStudentNewWorkCenterChange}
                options={workcentersOptions}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </BaseForm.Item>
          </BaseForm>
        </>
      )}
    </Modal>
  );
};
