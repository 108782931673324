import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  list,
  create,
  updateStudent,
  getOne,
  UpdateObject,
} from 'api/student.api';
import { SearchParams } from 'components/companies/types';
import { Student, initialValues } from 'components/students/types';

export interface StudentSlice {
  students: Student[];
  student: Student;
  status: 'empty' | 'fulfilled' | 'error' | 'updating';
}

const initialState: StudentSlice = {
  students: [],
  student: initialValues,
  status: 'empty',
};

export const doRegister = createAsyncThunk(
  'student/ ',
  async (StudentPayload: Student) => create(StudentPayload).then((res) => res)
);

export const doPatch = createAsyncThunk(
  'student/update',
  async (StudentPayload: UpdateObject) =>
    updateStudent(StudentPayload).then((res) => res)
);

export const doList = createAsyncThunk('student/list', async () =>
  list().then((res) => res)
);

export const doGetOne = createAsyncThunk(
  'student/getone',
  async (data: SearchParams) => getOne(data).then((res) => res)
);

export const doClear = createAction('students/clear', () => {
  return {
    payload: 'clear',
  };
});

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.students.push(action.payload);
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.students = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(doGetOne.fulfilled, (state, action) => {
      state.student = action.payload;
    });
    builder.addCase(doClear, (state, _action) => {
      state.students = [];
      state.status = 'empty';
    });
  },
});

export default studentSlice.reducer;
