import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Popover,
  Row,
  Table,
  Tooltip,
  TourProps,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import * as S from "../Agreements.styles";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doList, doListInactive, doRemove } from "store/slices/agreementSlice";
import { doList as doListSubsystems } from 'store/slices/subsystemSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Agreement, statusAgrementDict } from "components/Agreements/types";
import { Student } from "components/students/types";
import { WorkCenter } from "components/workcenters/types";
import { Company, Representative } from "components/companies/types";
import { AgreementDetail } from "../AgreementDetail";
import { initialValues } from "../types";
import { filterProps } from "components/common/FilterProps";
import { ColumnsType } from "antd/es/table";
import { colorStatus } from "../Agreements.styles";
import { OptionsDrawerBar } from "../details/OptionsDrawerBar";
import { ComentsAgreement } from "../details/ComentsAgreement";
import { Modal } from "../../common/Modal/Modal.styles";
import { CommentsOptions } from "../CommentsOptions";
import { valueDate } from "hooks/dateTreatment";
import { InfoTour } from "components/common/InfoTour/InfoTour";
import { InfoButton } from "components/common/buttons/Button/InfoButton";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { useTourCheck } from "hooks/useTourCheck";
import { notificationController } from "controllers/notificationController";
import { AgreementListHeader } from "./AgreementListHeader";

const { writeAgreements } = PermissionsToAccess;
export const getFullDate = (date: string): string => {
  const dateAndTime = date.split("T");
  return dateAndTime[0].split("-").reverse().join("-");
};

type AgreementListProps = {
  filter?: string;
};

export const AgreementsListTable: React.FC<AgreementListProps> = ({
  filter,
}: AgreementListProps) => {
  const dispatch = useAppDispatch();
  const { agreements, status, inactiveAgreements, inactiveListStatus } =
    useAppSelector((state) => state.agreement);
  const { subsystems, status: subsystemsStatus } = useAppSelector(
    (state) => state.susbsystem
  );
  const [agreementColumns, setAgreementColumns] = useState<Agreement[]>();
  const [selectedRowData, setSelectedRowData] = useState<Agreement>();
  const [agreementComents, setAgreementComents] = useState<Agreement>();
  const hasWriteAccess = CheckAccess(writeAgreements);

  const [filterLabel, setFilterLabel] = useState<string>("");
  const [currentModule, setCurrentModule] = useState<string>("agreement");
  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck(currentModule);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title: `Sección de Expedientes ${filterLabel}`,
      description: `En esta seción podrá registrar los expedientes ${filterLabel} establecidos entre su  plantel, los estudiantes y las empresas participantes en Educación Dual, asimismo podrá consultar los expedientes que ya hayan sido registrados.`,
      target: () => ref1.current,
    },
    {
      title: "Tabla, filtros y búsqueda",
      description:
        "Para realizar la búsqueda de expedientes ya registrados podrá hacer uso de los filtros que aparecen en el encabezado de la tabla",
      placement: "bottom",
    },
    {
      title: "Nuevo Expediente",
      description:
        "Para realizar el registro de un nuevo expediente deberá presionar el botón “Nuevo Expediente“ y vincular a un estudiante y empresa que hayan sido registrados previamente.",
      target: () => ref2.current,
      placement: "left",
    },
    {
      title: "Exportar expedientes",
      description: `Para descargar una hoja de cálculo con los datos de los expedientes ${filterLabel} deberá presionar el botón “Exportar expedientes“`,
      target: () => ref3.current,
    },
  ];
  // Modal
  const [modalVisible, setModalVisible] = useState(false);
  // Drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => { setOpen(true) };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };

  useEffect(() => {
    if (filter === "inactiveAgreements") {
      dispatch(doListInactive());
    } else {
      dispatch(doList());
    }
  }, [dispatch, status, filter, inactiveListStatus]);


  useEffect(() => {
    if (subsystemsStatus !== 'fulfilled') {
      dispatch(doListSubsystems());
    }
  }, [dispatch, subsystemsStatus, subsystems]);

  useEffect(() => {
    let filteredAgreements: Agreement[] = [];
    let label = "";
    let module = currentModule;

    if (filter === "inactiveAgreements") {
      filteredAgreements = inactiveAgreements;
      label = "en estado de baja (inactivos)";
      module = "downAgreement";
    } else if (filter === `pendingAgreements`) {
      label =`pendientes de revisión`;
      filteredAgreements = agreements.filter((e) => e.status === `PENDIENTE DE REVISION`);
      module = "agreement";
    } else if (filter === `draftAgreements`) {
      label =`incompletos`;
      filteredAgreements = agreements.filter((e) => e.status === `BORRADOR`);
      module = "agreement";
    } else if (filter === `rejectedAgreements`) {
      label =`que necesitan correcciones`;
      filteredAgreements = agreements.filter((e) => e.status === `RECHAZADO`);
      module = "agreement";
    } else {
      filteredAgreements = agreements;
      module = "agreement";
    }
    setFilterLabel(label);
    setAgreementColumns(filteredAgreements);
    setCurrentModule(module);
  }, [agreements, filter, currentModule, inactiveAgreements]);

  const onDeleteAgreement = async (data: Agreement) => {
    if(data.status === 'ACTIVO') {
      notificationController.warning({
        message: 'No es posible eliminar un expediente activo'
      })
      return ;
    }

    const agrNoActive = agreements.filter((agr) => agr.status !== 'ACTIVO');
    const allAgreements = [...agrNoActive, ...inactiveAgreements]
    const dataFiltered = allAgreements.filter(
      (agr) => agr._id === data._id
    );
    dispatch(doRemove(dataFiltered[0]))
      .unwrap()
      .then(() => {
        setAgreementColumns((pre) => {
          return pre!.filter((agreement) => agreement._id !== data._id);
        });
        notificationController.success({
          message: "Se eliminó el expediente correctamente",
        });
      })
      .catch((e) => {
        Sentry.captureMessage(`Not able to delete agreement: ${e}`);
        notificationController.error({
          message: "Error al eliminar expediente",
        });
      });
  };
  const columns: ColumnsType<Agreement> = [
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value: string, record: Agreement, _index: number) => (
        <span onMouseEnter={(e) => e.preventDefault()}>
          <Tooltip
            title={
              record.approvalStep
                ? `${value} - ${record.approvalStep.name}`
                : `${
                    statusAgrementDict[value as keyof typeof statusAgrementDict]
                  }`
            }
          >
            <FontAwesomeIcon
              icon={solid('circle')}
              style={colorStatus(value)}
            />
          </Tooltip>
        </span>
      ),
      ...filterProps,
      onFilter: (value, record) => {
        return record.status
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Folio',
      dataIndex: 'sequence',
      key: 'sequence',
      align: 'center',
      render: (_value: string, record: Agreement, _index: number) => {
        return record.sequence;
      },
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        a.sequence && b.sequence ? a.sequence - b.sequence : -1,
    },
    {
      title: 'Fecha de Finalización',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (value: Date | string, _record: Agreement, _index: number) =>
        valueDate(value),
      ...filterProps,
      onFilter: (value, record) => {
        let recordDateString = valueDate(record.endDate);
        recordDateString = recordDateString !== false ? recordDateString : '';
        return recordDateString
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
        new Date(a.endDate).getTime() - new Date(b.endDate).getTime(),
    },
    {
      title: 'Alumno',
      dataIndex: 'studentId',
      key: 'student',
      render: (student: Student) =>
        student
          ? `${student.name} ${student.paternalLastname ?? ''} ${
              student.maternalLastname ?? ''
            }`
          : '',
      ...filterProps,
      onFilter: (value, record) => {
        const fullName = `${record.studentId?.name} ${
          record.studentId?.paternalLastname ?? ''
        } ${record.studentId?.maternalLastname ?? ''}`;
        return fullName.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => {
        const fullNameA = `${a.studentId.name} ${
          a.studentId.paternalLastname ?? ''
        } ${a.studentId.maternalLastname ?? ''}`;
        const fullNameB = `${b.studentId.name} ${
          b.studentId.paternalLastname ?? ''
        } ${b.studentId.maternalLastname ?? ''}`;
        return fullNameA.localeCompare(fullNameB);
      },
    },
    {
      title: 'Plantel',
      dataIndex: 'workCenterId',
      key: 'workCenter',
      ...filterProps,
      render: (workCenterId: WorkCenter) =>
        workCenterId &&
        workCenterId.nameShort &&
        `${workCenterId?.nameShort ?? ''}`,
      onFilter: (value, record) => {
        return record.workCenterId && record.workCenterId.nameShort
          ? record.workCenterId.nameShort
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : ''.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
        a.workCenterId?.nameShort.localeCompare(b.workCenterId?.nameShort),
    },
    {
      title: 'Nivel Educativo',
      dataIndex: 'educativeLevel',
      key: 'educativeLevel',
      ...filterProps,
      onFilter: (value, record) => {
        return record.educativeLevel
          ? record.educativeLevel
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : ''.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => {
        if (a.educativeLevel !== undefined && b.educativeLevel !== undefined) {
          return a.educativeLevel.localeCompare(b.educativeLevel);
        } else {
          if (a.educativeLevel === undefined) {
            return -1;
          } else {
            return 1;
          }
        }
      },
    },
    {
      title: 'Empresa',
      dataIndex: 'companyId',
      key: 'companies',
      ...filterProps,
      render: (companyId: Company) =>
        companyId ? `${companyId?.name ?? ''}` : '',
      onFilter: (value, record) => {
        return record.companyId && record.companyId.name
          ? record.companyId?.name
              .toLowerCase()
              .includes(value.toString().toLowerCase()) || false
          : ''.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
        a.companyId && b.companyId
          ? a.companyId.name.localeCompare(b.companyId.name)
          : -1,
    },
    {
      title: 'Contacto de la empresa',
      dataIndex: 'representativeId',
      key: 'representative',
      render: (rep: Representative) => (rep ? `${rep.name}` : ''),
      ...filterProps,
      onFilter: (value, record) => {
        return record.representativeId && record.representativeId.name
          ? record.representativeId.name
              .toLowerCase()
              .includes(value.toString().toLowerCase()) || false
          : ''.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
        a.representativeId && b.representativeId
          ? a.representativeId.name.localeCompare(b.representativeId.name)
          : a.representativeId
          ? a.representativeId.name.localeCompare('')
          : b.representativeId
          ? b.representativeId.name.localeCompare('')
          : ''.localeCompare(''),
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: 'x',
      align: 'center',
      render: (data: Agreement) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              style={{ marginRight: '10%' }}
              icon={
                <FontAwesomeIcon
                  icon={solid('comment-dots')}
                  style={{ color: '#001529' }}
                />
              }
              onClick={() => {
                showModal(data);
              }}
            />
            <Popover
              style={{ display: hasWriteAccess ? 'block' : 'none' }}
              content={
                <Button
                  type='primary'
                  danger
                  block
                  onClick={() => {
                    onDeleteAgreement(data);
                  }}
                >
                  Confirmar
                </Button>
              }
              trigger='click'
              placement='left'
            >
              <FontAwesomeIcon
                icon={solid('trash')}
                style={{ color: 'red', display: hasWriteAccess ? '' : 'none' }}
              />
            </Popover>
          </span>
        );
      },
    },
  ];

  const showModal = (record: Agreement) => {
    setAgreementComents(record);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <S.Wrapper id='agreements-list-container'>
        <S.Card
          id='agreements-list'
          title={
            <>
              <span ref={ref1}>
                Expedientes Educación Dual {filterLabel}{' '}
                <InfoButton onClick={() => setOpenTour(true)} />
              </span>
            </>
          }
          padding='1.25rem'
          extra={
            <AgreementListHeader
              newAgreementRef={ref2}
              exportAgreementRef={ref3}
              module={currentModule}
            />
          }
        >
          <Drawer
            title={
              <Row gutter={10} align='middle' justify={'space-between'}>
                <Col span={6}>
                  <h4>Detalles del expediente dual</h4>
                </Col>
                <Col span={18}>
                  <OptionsDrawerBar
                    rowSelected={selectedRowData ?? initialValues}
                    closeDrawer={setOpen}
                    onDelete={onDeleteAgreement}
                  />
                </Col>
              </Row>
            }
            placement='right'
            width='50%'
            onClose={onCloseDrawer}
            destroyOnClose
            open={open}
          >
            <AgreementDetail
              selectedRow={selectedRowData ?? initialValues}
              closeModalDetails={setModalVisible}
            />
          </Drawer>
          <Table
            dataSource={agreementColumns}
            columns={columns}
            onRow={(record, _rowIndex) => {
              return {
                onClick: (_event) => {
                  setSelectedRowData(record);
                  showDrawer();
                },
              };
            }}
            size='small'
            rowKey='_id'
          />
          <Descriptions title='Estados' layout='vertical'>
            <Descriptions.Item label='Activo'>
              <FontAwesomeIcon
                icon={solid('circle')}
                style={colorStatus('ACTIVO')}
              />
            </Descriptions.Item>
            <Descriptions.Item label='Pendiente de revisión'>
              <FontAwesomeIcon
                icon={solid('circle')}
                style={colorStatus('PENDIENTE DE REVISION')}
              />
            </Descriptions.Item>
            <Descriptions.Item label='Incompleto'>
              <FontAwesomeIcon
                icon={solid('circle')}
                style={colorStatus('BORRADOR')}
              />
            </Descriptions.Item>
            <Descriptions.Item label='Necesita correcciones'>
              <FontAwesomeIcon
                icon={solid('circle')}
                style={colorStatus('RECHAZADO')}
              />
            </Descriptions.Item>
            <Descriptions.Item label='Inactivo'>
              <FontAwesomeIcon
                icon={solid('circle')}
                style={colorStatus('INACTIVO')}
              />
            </Descriptions.Item>
          </Descriptions>
          <Modal
            open={modalVisible}
            title='Comentarios'
            destroyOnClose
            onCancel={handleCancel}
            footer={
              <CommentsOptions
                closeModal={setModalVisible}
                selectedRow={agreementComents ?? initialValues}
              />
            }
          >
            <ComentsAgreement selectedRow={agreementComents ?? initialValues} />
          </Modal>
        </S.Card>
        <InfoTour
          onClose={() => setOpenTour(false)}
          open={openTour}
          steps={steps}
          block={'start'}
          module={currentModule}
          isFirstTime={isFirstTime}
        />
      </S.Wrapper>
    </>
  );
};
