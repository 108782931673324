import { httpApi } from "api/http.api";
import { Student, sendCommentObj } from "components/students/types";
import { SearchParams } from "./../components/companies/types";
const controller = `student`;

export type UpdateObject = {
  changedValues: Student,
  student: Student
}


export const create = (StudentPayload: Student): Promise<Student> =>
  httpApi
    .post<Student>(`${controller}`, { ...StudentPayload })
    .then(({ data }) => data);

export const list = (): Promise<Student[]> =>
  httpApi.get<Student[]>(`${controller}`).then(({ data }) => data);
export const search = ({ searchParam, searchValue }: SearchParams) =>
  httpApi
    .get<Student[]>(`${controller}/search/${searchParam}/${searchValue}`)
    .then(({ data }) => data);

  export const getOne = (searchp: SearchParams) =>
    httpApi
      .get<Student>(`${controller}/getone/${searchp.searchParam}/${searchp.searchValue}`)
      .then(({ data }) => data);

export const updateStudent=(StudentPayload: UpdateObject): Promise<Student> =>
httpApi
  .put<Student>(`${controller}`, { ...StudentPayload })
  .then(({ data }) => data);

export const comment = (
  studentPayload: sendCommentObj
): Promise<Student> =>
  httpApi
    .patch<Student>(`${controller}/comment`, { ...studentPayload })
    .then(({ data }) => data);
