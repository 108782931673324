import { Button, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import * as S from "./WorkCenter.styles";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { doList, doRemove } from "store/slices/workCenterSlice";
import { doList as doListSubsystem } from "store/slices/subsystemSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { WorkCenter } from "components/workcenters/types";
import { WORKCENTERS_PATH } from "components/router/AppRouter";
import { filterProps } from "components/common/FilterProps";
import { ColumnsType } from "antd/es/table/interface";
import { Employee } from "components/employees/types";
import { WorkcenterDetail } from "./WorkcenterDetail";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { doList as doListMunicipalities } from "store/slices/municipalitySlice";
import { Municipality } from "components/municipality/types";

const {writeWorkcenters} = PermissionsToAccess


export const WorkCenterListTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workcenters, status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const { municipalities, status : municipalityStateStatus } = useAppSelector((state) => state.municipality);

  const { subsystems, status: subsystemStatus } = useAppSelector((state) => state.susbsystem);
  const [workcenterColumns, setWorkcenterColumns] = useState<WorkCenter[]>();
  const [selectedRowData, setSelectedRowData] = useState<WorkCenter | null>(null)
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);

  useEffect(() => {
    if(workcenterStateStatus !== 'fulfilled'){
      dispatch(doList());
    }
  }, [dispatch, workcenterStateStatus]);

  useEffect(() => {
    if(municipalityStateStatus !== 'fulfilled'){
      dispatch(doListMunicipalities());
    }
  }, [dispatch, municipalityStateStatus]);

  useEffect(() => {
    if(subsystemStatus !== 'fulfilled'){
      dispatch(doListSubsystem());
    }
  }, [dispatch, subsystemStatus]);

  useEffect(() => {
    const workcentersData = workcenters.map((workcenter) => {
      const subsystem = subsystems.find(
        (subsystem) => subsystem._id === workcenter.subsystemId
      );
      const municipality = municipalities.find(
        (mun) => workcenter?.municipality !== undefined ? mun._id === (workcenter.municipality as Municipality)._id : undefined
      );
      return {
        ...workcenter,
        educativeLevel: subsystem?.educativeLevel,
        municipality: municipality
      };

    });
    setWorkcenterColumns(workcentersData);
  }, [workcenters, subsystems, municipalities]);
  const onDeleteWorkSpace = async (data: WorkCenter) => {
    const dataFiltered = workcenters.filter(
      (workspace) => workspace._id === data._id
    );
    await doRemove(dataFiltered[0]);
    setWorkcenterColumns((pre) => {
      return pre!.filter((workcenter) => workcenter._id !== data._id);
    });
    return true;
  };
  const columns: ColumnsType<WorkCenter> = [
    {
      title: "Subsistema / IES",
      dataIndex: "subsystemShort",
      key: "subsystemShort",
      ...filterProps,
      onFilter: (value, record) => {
        return record.subsystemShort && record.subsystemShort
          ? record.subsystemShort.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
      a.subsystemShort && b.subsystemShort
      ? a.subsystemShort.localeCompare(b.subsystemShort)
      : a.subsystemShort
      ? a.subsystemShort.localeCompare("")
      : b.subsystemShort
      ? b.subsystemShort.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "Plantel",
      dataIndex: "nameShort",
      key: "index",
      sorter: (a, b) => a.name.localeCompare(b.nameShort),
      ...filterProps,
      onFilter: (value, record) => {
        return record.nameShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Municipio",
      dataIndex: ['municipality', 'name'],
      key: "municipality",
      sorter: (a, b) => {
        const nameA = (a.municipality! as Municipality).name || '';
        const nameB = (b.municipality! as Municipality).name || '';
        return nameA.localeCompare(nameB);
      },
      ...filterProps,
      onFilter: (value, record) => {
        const hasMunicipality = record?.municipality && (record.municipality! as Municipality).name;      
        if (hasMunicipality) {
          return (record.municipality! as Municipality).name
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
        return false;
      },
    },
    {
      title: 'Nivel Educativo',
      dataIndex: 'educativeLevel',
      key: 'educativeLevel',
      sorter: (a, b) => {
        if (a.educativeLevel === undefined && b.educativeLevel === undefined) {
          return 0;
        } else if (a.educativeLevel === undefined) {
          return 1;
        } else if (b.educativeLevel === undefined) {
          return -1;
        }
        return a.educativeLevel.localeCompare(b.educativeLevel);
      },   
      ...filterProps,
      onFilter: (value, record) => {
        if (record.educativeLevel === undefined) {
          return false; 
        }
        return record.educativeLevel
          .toUpperCase()
          .includes(value.toString().toUpperCase());
      },
    },
    {
      title: "Director(a) (subsistema) / Rector(a) (IES)",
      dataIndex: "principal",
      key: "principal",
      ...filterProps,
      render: (principal: Employee) => `${principal ? principal.name : ""}`,
      onFilter: (value, record) => {
        return record.principal && record.principal.name
          ? record.principal?.name.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
      a.principal && b.principal
      ? a.principal.name.localeCompare(b.principal.name)
      : a.principal
      ? a.principal.name.localeCompare("")
      : b.principal
      ? b.principal.name.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "CCT",
      dataIndex: "keySep",
      key: "principal",
      ...filterProps,
      onFilter: (value, record) => {
        return record.keySep
          ? record.keySep.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
      a.keySep && b.keySep
      ? a.keySep.localeCompare(b.keySep)
      : a.keySep
      ? a.keySep.localeCompare("")
      : b.keySep
      ? b.keySep.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "Acción",
      dataIndex: "",
      align: "center",
      key: "x",
      render: (data: WorkCenter) => {
        return (
          <div>
            <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popover
              content={
                <Button
                  type='primary'
                  danger
                  block
                  onClick={() => {
                    onDeleteWorkSpace(data);
                  }}
                >
                  Confirmar
                </Button>
              }
              trigger='click'
              placement='left'
            >
              <FontAwesomeIcon icon={solid("trash")} style={{ color: "red" }} />
            </Popover>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="workcenters-list"
          title={`Planteles`}
          padding="1.25rem"
          extra={
            CheckAccess(writeWorkcenters) && (
              <Button
                type="primary"
                onClick={() => {
                  navigate(`${WORKCENTERS_PATH}/nuevo`);
                }}
              >
                Nuevo Plantel
              </Button>
            )
          }
        >
          <Table
            dataSource={workcenterColumns}
            columns={
              CheckAccess(writeWorkcenters)
                ? columns
                : columns.filter((col) => col.title !== "Acción")
            }
            rowKey="_id"
            tableLayout="fixed"
            onRow={(record, _rowIndex) => {
              return {
                onClick: (_event) => {
                  setSelectedRowData(record);
                  setShowDetailDrawer(true);
                },
              };
            }}
          />
        </S.Card>
        <WorkcenterDetail
          onClose={() => {
            setShowDetailDrawer(false);
          }}
          selectedRow={selectedRowData}
          open={showDetailDrawer}
        />
      </S.Wrapper>
    </>
  );
};
