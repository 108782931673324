import {
  Button,
  Popover,
  Modal,
  Form,
  DatePicker,
  Row,
  Col,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Agreement } from "components/Agreements/types";
import TextArea from "antd/es/input/TextArea";
import { nextApprovalStep, statusUpdate } from "api/agreements.api";
import { notificationController } from "controllers/notificationController";
import { doList } from "store/slices/agreementSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Link } from "react-router-dom";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import { doGetApprovalProcess } from "store/slices/configSlice";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { IsAdultStudent } from "../utils/utils";
import { PermissionsType } from "components/employees/types";
import { RangePickerProps } from "antd/es/date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { requiredAdultFields, requiredFields } from "../constants";

const { writeAgreements, editApprovedAgreements } = PermissionsToAccess;

type OptionProps = {
  rowSelected: Agreement;
  closeDrawer: (arg0: boolean) => void;
  onDelete?: (data: Agreement) => void;
};
export const OptionsDrawerBar = ({
  rowSelected,
  closeDrawer,
  onDelete,
}: OptionProps) => {
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [nextRevisionBtnVisible, setnextRevisionBtnVisible] = useState(false);
  const [approveBtnVisible, setApproveBtnVisible] = useState(false);
  const [rejectBtnVisible, setRejectBtnVisible] = useState(false);
  const [inactiveBtnVisible, setInactiveBtnVisible] = useState(false);
  const [valuesTextArea, setValuesTextArea] = useState<string>("");
  const [newStatus, setNewStatus] = useState<string>("");
  const [inactiveDate, setinactiveDate] = useState<undefined | Date>(undefined);
  const [editRoute, setEditRoute] = useState<string>("edit");
  const [editPermission, setEditPermission] =
    useState<PermissionsType>(writeAgreements);
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const hasWriteAccess = CheckAccess(writeAgreements);

  //config for approval roles
  const { validApprovalRoles, configStatus } = useAppSelector(
    (state) => state.config
  );

  useEffect(() => {
    if (configStatus !== "fulfilled") {
      dispatch(doGetApprovalProcess());
    }
    showNextRevisionBtn();
    showRejectBtn();
    showApproveBtn();
    showInactiveBtn();
  }, [dispatch, validApprovalRoles, configStatus, rowSelected]);

  useEffect(() => {
    if (rowSelected && rowSelected.approvalStep !== undefined) {
      showNextRevisionBtn();
      showRejectBtn();
      showApproveBtn();
      showInactiveBtn();
    }
    if (rowSelected && rowSelected.status) {
      checkEditAgreement();
    }
  }, [rowSelected]);

  const isAgreementComplete = () => {
    const agreementSelected: { [key: string]: unknown } = {
      sequence: rowSelected.sequence,
      studentId: rowSelected.studentId,
      workCenterId: rowSelected.workCenterId,
      companyId: rowSelected.companyId,
      representativeId: rowSelected.representativeId,
      legalRepresentativeId: rowSelected.legalRepresentativeId,
      startDate: rowSelected.startDate,
      endDate: rowSelected.endDate,
      status: rowSelected.status,
      learningAgreementFileId: rowSelected.learningAgreementFileId,
      colaborationAgreementFileId: rowSelected.colaborationAgreementFileId,
      tutorIdFileId: rowSelected.tutorIdFileId,
      tutorAuthLetterFileId: rowSelected.tutorAuthLetterFileId,
      insuranceFileId: rowSelected.insuranceFileId,
      rotationPlanFileId: rowSelected.rotationPlanFileId,
      learningPositionsFieldId: rowSelected.learningPositionsFieldId,
      matrixFieldId: rowSelected.matrixFieldId,
      principalFileId: rowSelected.principalFileId,
      principal: rowSelected.principal,
      principalPosition: rowSelected.principalPosition,
      rotationTimes: rowSelected.rotationTimes,
      rotationPositions: rowSelected.rotationPositions,
      hoursPerWeek: rowSelected.hoursPerWeek,
    };
    const requiredAgreementFields: { [key: string]: unknown }[] =
      !IsAdultStudent(rowSelected.studentId)
        ? requiredFields
        : requiredAdultFields();
    const hasAllFields = requiredAgreementFields.map((field) => {
      const returns = agreementSelected[field.id as string] ? true : false;
      if (!returns) {
        console.log("falta", field);
      }
      return returns;
    });
    return !hasAllFields.includes(false);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current > dayjs().endOf("day");
  };

  const checkEditAgreement = () => {
    if (rowSelected.status === "ACTIVO") {
      setEditPermission(editApprovedAgreements);
      setEditRoute("editapproved");
    } else {
      setEditPermission(writeAgreements);
      setEditRoute("edit");
    }
  };

  const showNextRevisionBtn = () => {
    if (rowSelected.status === "ACTIVO" || rowSelected.status === "INACTIVO") {
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (
      JSON.stringify(rowSelected.approvalStep) ===
      JSON.stringify(validApprovalRoles[0])
    ) {
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (user?.role && user?.role === 1) {
      setnextRevisionBtnVisible(true);
      return true;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
      JSON.stringify(rowSelected.approvalStep)
    ) {
      setnextRevisionBtnVisible(true);
      return true;
    }
    if (
      rowSelected.status === "BORRADOR" ||
      (rowSelected.status === "RECHAZADO" &&
        (JSON.stringify(user?.approvementRole) ===
          JSON.stringify(validApprovalRoles[0]) ||
          JSON.stringify(user?.approvementRole) ===
            JSON.stringify(validApprovalRoles[1])))
    ) {
      setnextRevisionBtnVisible(true);
      return true;
    }
  };
  const showRejectBtn = () => {
    if (
      rowSelected.status === "ACTIVO" ||
      rowSelected.status === "INACTIVO" ||
      rowSelected.status === "RECHAZADO"
    ) {
      setRejectBtnVisible(false);
      return false;
    }
    if (user?.role && user?.role === 1) {
      setRejectBtnVisible(true);
      return true;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(rowSelected.approvalStep) ||
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(validApprovalRoles[0])
    ) {
      setRejectBtnVisible(true);
      return true;
    }
    setRejectBtnVisible(false);
    return false;
  };
  const showApproveBtn = () => {
    if (
      rowSelected.status === "ACTIVO" ||
      rowSelected.status === "INACTIVO" ||
      rowSelected.status === "RECHAZADO"
    ) {
      setApproveBtnVisible(false);
      return false;
    }
    if (!isAgreementComplete()) {
      setApproveBtnVisible(false);
      return false;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(rowSelected.approvalStep) &&
      JSON.stringify(rowSelected.approvalStep) ===
        JSON.stringify(validApprovalRoles[0])
    ) {
      setApproveBtnVisible(true);
      return true;
    }
    if (
      (user?.role && user?.role === 1) ||
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(validApprovalRoles[0])
    ) {
      setApproveBtnVisible(true);
      return true;
    }
    setApproveBtnVisible(false);
    return false;
  };

  const showInactiveBtn = () => {
    if (rowSelected.status === "ACTIVO") {
      setInactiveBtnVisible(true);
      return true;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(validApprovalRoles[0]) &&
      rowSelected.status === "PENDIENTE DE REVISION"
    ) {
      setInactiveBtnVisible(true);
      return true;
    }
    setInactiveBtnVisible(false);
    return false;
  };
  const handleDelete = () => {
    if (!hasWriteAccess) {
      notificationController.error({
        message: "No tienes permiso para eliminar registros",
      });
      return;
    }
    if (onDelete) {
      onDelete(rowSelected);
      closeDrawer(false);
    }
  };
  const handleOk = (newStatus: string) => {
    if (newStatus === "INACTIVO" && !inactiveDate) {
      notificationController.warning({
        message: "Selecciona una fecha de baja ",
      });
      return;
    }
    if (
      newStatus === "INACTIVO" &&
      (!rowSelected.hoursPerWeek ||
        rowSelected.hoursPerWeek <= 0 ||
        !rowSelected.startDate)
    ) {
      notificationController.warning({
        message:
          "No puedes dar de baja un convenio que no tiene número de horas a la semana o fecha de inicio",
      });
      return;
    }
    if (
      (newStatus === "INACTIVO" || newStatus === "RECHAZADO") &&
      !valuesTextArea
    ) {
      notificationController.warning({
        message: "Escribe un motivo de baja o las correcciones que necesita",
      });
      return;
    }
    statusUpdate({
      id: rowSelected._id ?? "",
      comment: valuesTextArea,
      status: newStatus,
      approvalStep: rowSelected.approvalStep,
      currentStatus: rowSelected.status,
      inactiveDate: inactiveDate,
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList());
        setModalVisible(false);
        closeDrawer(false);
      })
      .catch((_error) => {
        if (newStatus === "INACTIVO") {
          notificationController.error({
            message: "Error al dar de baja",
            description:
              "Revisa que la fecha de baja y la cantidad de horas a la semana sean correctas",
          });
        } else {
          notificationController.error({
            message: "Error",
            description: "Ha fallado el cambio de status",
          });
        }
        setModalVisible(false);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValuesTextArea(e.target.value);
  };
  const approveAgreement = () => {
    setLoading(true);
    if (
      rowSelected.companyId.status !== "Verificada" ||
      rowSelected.representativeId.status !== "Verificada"
    ) {
      notificationController.error({
        message: "Error ",
        description:
          "Un expediente no puede ser aprobado si la empresa o sus representantes no se han verificado",
      });
      setLoading(false);
      return false;
    }
    statusUpdate({
      id: rowSelected._id ?? "",
      comment: "Aprobado",
      status: "ACTIVO",
      approvalStep: rowSelected.approvalStep,
      currentStatus: rowSelected.status,
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList());
        setModalVisible(false);
        closeDrawer(false);
        setApproveBtnVisible(false);
      })
      .catch((_error) => {
        notificationController.error({
          message: "Error ",
          description: "Error al aprobar expediente",
        });
        setModalVisible(false);
      });
    setLoading(false);
  };

  const nextRevision = () => {
    nextApprovalStep(rowSelected._id ?? "")
      .then(() => {
        notificationController.success({
          message: "expediente enviado a revisión",
        });
        dispatch(doList());
        setModalVisible(false);
        closeDrawer(false);
        setnextRevisionBtnVisible(false);
      })
      .catch((_error) => {
        notificationController.error({
          message: "Error ",
        });
        setModalVisible(false);
      });
  };

  const deleteBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button type="primary" danger onClick={handleDelete}>
          Eliminar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" danger>
        <FontAwesomeIcon icon={solid("trash")} style={{ color: "white" }} />
      </Button>
    </Popover>
  );

  const approveBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#329213" }}
          onClick={approveAgreement}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#3fb618" }}>
        Aprobar
      </Button>
    </Popover>
  );

  const rejectBtn = (
    <Button
      className="btn-dropdown-custom"
      danger
      onClick={() => {
        setModalVisible(true);
        setNewStatus("RECHAZADO");
      }}
    >
      Necesita correcciones
    </Button>
  );

  const inactiveBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#69707a" }}
          onClick={() => {
            setModalVisible(true);
            setNewStatus("INACTIVO");
          }}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#69707a" }}>
        Dar de baja
      </Button>
    </Popover>
  );

  const sendToRevisionBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#FF8C00" }}
          onClick={nextRevision}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#FF8C00" }}>
        Enviar a siguiente revisión
      </Button>
    </Popover>
  );

  const requireRevisionBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#FF8C00" }}
          onClick={() => {
            handleOk("PENDIENTE DE REVISION");
          }}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#FF8C00" }}>
        Requiere revisión de subsistema
      </Button>
    </Popover>
  );

  const editBtn = (
    <Link to={`${AGREEMENTS_PATH}/${editRoute}/${rowSelected._id}`}>
      <Button className="btn-dropdown-custom" type="primary">
        Editar
      </Button>
    </Link>
  );
  return (
    <>
      <Row gutter={5} justify="space-evenly">
        {onDelete && <Col flex={1}>{deleteBtn}</Col>}
        {approveBtnVisible && <Col flex={1}>{approveBtn}</Col>}
        {CheckAccess(editPermission) && <Col flex={1}>{editBtn}</Col>}
      </Row>
      <Divider style={{ margin: "5px 0px" }} />
      <Row gutter={5} justify="space-evenly">
        {rejectBtnVisible && <Col flex={1}>{rejectBtn}</Col>}
        {inactiveBtnVisible && rowSelected.status === "ACTIVO" && (
          <Col flex={1}>{inactiveBtn}</Col>
        )}
        {nextRevisionBtnVisible && !inactiveBtnVisible && (
          <Col flex={1}>{sendToRevisionBtn}</Col>
        )}
        {(inactiveBtnVisible || rowSelected.status === "INACTIVO") && (
          <Col flex={1}>{requireRevisionBtn}</Col>
        )}
      </Row>
      <Modal
        title="Correcciones necesarias o motivo baja"
        open={modalVisible}
        destroyOnClose
        onOk={() => handleOk(newStatus)}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" danger onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            type="primary"
            onClick={() => handleOk(newStatus)}
            style={{ backgroundColor: "#3fb618" }}
            loading={loading}
          >
            Guardar
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          style={{ marginTop: 25 }}
          onFinish={() => handleOk(newStatus)}
        >
          <Form.Item
            label="Motivo"
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <TextArea
              placeholder="Ingresa las correcciones que se necesitan o el motivo baja del expediente"
              size="small"
              allowClear
              required
              onChange={handleChange}
            />
          </Form.Item>
          {newStatus === "INACTIVO" && (
            <Form.Item
              name="inactiveDate"
              label={"Fecha de baja"}
              rules={[{ required: true, message: "Este campo es obligatorio" }]}
            >
              <DatePicker
                onChange={(e) => setinactiveDate(e!.toDate())}
                disabledDate={disabledDate}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
